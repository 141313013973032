import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                sidenav: '#0a0e13',
                grey: '#8fa3b0'
            },
        },
    },
});
