import Vue from 'vue';
import { Module, Action, VuexModule, Mutation } from 'vuex-module-decorators';
import { DeviceType } from '@/static/enums/deviceType'
import { isMobile, isTablet } from '@/utils/isMobile';
// @ts-ignore
import { debounce } from '@/utils/debounce'


function resizeHandler(commit: ((name: string, param: boolean) => void)): void {
    commit('setIsMobile', isMobile());
    commit('setIsTablet', isTablet());
}
const debounceResizeHandler = debounce(resizeHandler, 16.6)

@Module({ namespaced: true })
class LayoutModule extends VuexModule {
    public isMobile: boolean = isMobile();
    public isTablet: boolean = isTablet();
    public isIOS = false;

    public deviceType: DeviceType = DeviceType.DESKTOP;

    @Action
    initLayoutModule() {
        resizeHandler(this.context.commit)

        window.addEventListener('resize', () => {
            debounceResizeHandler(this.context.commit)
        })

        window.addEventListener('scroll', () => {
            debounceResizeHandler(this.context.commit)
        })


        if (Vue.prototype.$device.isMobile) {
            this.context.commit('setDeviceType', DeviceType.MOBILE)
        }

        if (Vue.prototype.$device.isTablet) {
            this.context.commit('setDeviceType', DeviceType.TABLET)
        }

        if (Vue.prototype.$device.isDesktop) {
            this.context.commit('setDeviceType', DeviceType.DESKTOP)
        }

        this.context.commit('setIsIOS', Vue.prototype.$device.isIOS)
    }

    @Mutation
    setIsMobile(isMobile: boolean): void {
        this.isMobile = isMobile
    }

    @Mutation
    setIsTablet(isTablet: boolean): void {
        this.isTablet = isTablet
    }

    @Mutation
    setDeviceType(device: DeviceType) {
        this.deviceType = device;
    }

    @Mutation
    setIsIOS(isIOS: boolean) {
        this.isIOS = isIOS;
    }
}

export default LayoutModule;