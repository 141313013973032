
import MobileMixin from "@/mixins/mobileMixin";
import {Component} from "vue-property-decorator";
import KPolicyPrivacy from "@/components/KPolicyPrivacy.vue";

@Component({
  components: {KPolicyPrivacy}
})
export default class KFooter extends MobileMixin{

  privacyPolicy = false;

  openPrivacyPolicy() {
    window.open("https://www.iubenda.com/privacy-policy/100000000", "_blank");
  }
}
