
import MobileMixin from "@/mixins/mobileMixin";
import {Component} from "vue-property-decorator";
import KAppHeader from "@/components/KAppHeader.vue";
import KFooter from "@/components/KFooter.vue";

@Component({
  components: {KFooter, KAppHeader}
})
export default class App extends MobileMixin {

  mounted() {
    console.log('App mounted !');
  }

}
