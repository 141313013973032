import axiosInstance from "@/api/services/axios-instance";
import {KLeague} from "@/api/models/KLeague";
import {KRanking} from "@/api/models/KRanking";
import {KSeries} from "@/api/models/KSeries";
import {KMatchDetails} from "@/api/models/KMatch";
import {KPlayer} from "@/api/models/KTeam";

export abstract class LolService {

    static async getAllLeagues(): Promise<KLeague[]> {
        const url = `/api/lol/league/all`;
        const response = await axiosInstance.get<KLeague[]>(url);
        return response.data;
    }

    static async getLeagueRanking(slug: string): Promise<KRanking[]> {
        const url = `/api/lol/league/ranking/${slug}`;
        const response = await axiosInstance.get<KRanking[]>(url);
        return response.data;
    }

    static async getSeriesBySlug(slug: string): Promise<KSeries[]> {
        const url = `/api/lol/league/series/${slug}`;
        const response = await axiosInstance.get<KSeries[]>(url);
        return response.data;
    }

    static async getMatchesBySeriesSlug(slug: string): Promise<KMatchDetails[]> {
        const url = `/api/lol/match/${slug}`;
        const response = await axiosInstance.get<KMatchDetails[]>(url);
        return response.data;
    }

    static async getPlayersBySeriesSlugAndAcronym(slug: string, acronym: string): Promise<KPlayer[]> {
        const url = `/api/lol/team/${slug}/${acronym}`;
        const response = await axiosInstance.get<KPlayer[]>(url);
        return response.data;
    }

    static async getLiveMatches(): Promise<KMatchDetails[]> {
        const url = `/api/lol/match/live`;
        const response = await axiosInstance.get<KMatchDetails[]>(url);
        return response.data;
    }

}
