import Vue from 'vue'
import Vuex from 'vuex'

import LayoutModule from "@/store/modules/layout";
import LolModule from "@/store/modules/lol";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    LayoutModule,
    LolModule
  }
})
