
import MobileMixin from "@/mixins/mobileMixin";
import {Component, Emit, Prop} from "vue-property-decorator";

@Component
export default class KPolicyPrivacy extends MobileMixin{

  @Prop({ type: Boolean, default: false }) openDialog: boolean;

  @Emit()
  cancel() {
  }
}
