export class KLeague {
  id = 0;
  image_url = '';
  modified_at = '';
  name = '';
  series: KLeagueSeries[] = [];
  slug = '';
  url = '';
}

export class KLeagueSeries {
  begin_at = '';
  end_at = '';
  full_name = '';
  id = 0;
  league_id = 0;
  modified_at = '';
  name = '';
  season = '';
  slug = '';
  winner_id = 0;
  winner_type = '';
  year = 0;
}