import { Component, Vue } from "vue-property-decorator";
import { namespace } from 'vuex-class';
const layoutModule = namespace('LayoutModule')

@Component
export default class MobileMixin extends Vue {
  @layoutModule.State
  public isMobile: boolean;

  @layoutModule.State
  public isTablet: boolean;

  @layoutModule.State
  public isIOS: boolean;
}