import { VueConstructor } from 'vue/types/umd'
import Dayjs from "dayjs";
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from "dayjs";
import 'dayjs/locale/fr';


export default {
  install(Vue: VueConstructor): void {
    Vue.filter('capitalize', (value: string) => {
      if (!value) {
        return '';
      }
      return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    });

    Vue.filter('formatLocalDateToMonthYear', (value: string) => {
      if (value) {
        return Dayjs(value).format("MMMM YYYY").valueOf();
      }
      return value;
    });

    Vue.filter('formatLiteralDate', (value: string) => {
      if (value) {
        return Dayjs(value).format("D MMMM YYYY").valueOf();
      }
      return value;
    });

    Vue.filter('timeFromNow', (value: string) => {
      Dayjs.extend(relativeTime)
      if (value) {
        return Dayjs(value).fromNow();
      }
      return value;
    });

    Vue.filter('formatLocaleDate', (value: string) => {
      return Dayjs(value).locale('fr').format("DD/MM/YYYY").valueOf();
    });

    Vue.filter('applyFormatDate', (value: string, format: string) => {
      Dayjs.extend(localizedFormat);
      if (value) {
        return Dayjs(value).format(format);
      }
      return value;
    })

    Vue.filter('longDate', (value: string) => {
      return dayjs(value).locale('fr').format('dddd D MMMM ');
    })

    Vue.filter('onlyHour', (value: string) => {
      return Dayjs(value).format("HH");
    })

    Vue.filter('onlyMinute', (value: string) => {
      return Dayjs(value).format("mm");
    })

  }
}
