
import MobileMixin from "@/mixins/mobileMixin";
import {Component} from "vue-property-decorator";

@Component({
  components: {}
})
export default class KAppHeader extends MobileMixin {

  goHome(): void {
    if (this.$route.name === "Home") return;
    this.$router.push({name: "Home"});
  }
}
