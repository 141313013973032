import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {KLeague} from "@/api/models/KLeague";
import {LolService} from "@/api/services/lolService";
import {KTeam} from "@/api/models/KTeam";

@Module({namespaced: true})
export default class LolModule extends VuexModule {

    public leagues: KLeague[] = [];

    public leagueSelected: KLeague = new KLeague();
    public isGetLeaguesLoading: boolean = false;

    @Mutation
    public setLeagues(kLeagues: KLeague[]): void {
        this.leagues = kLeagues;
    }

    @Mutation
    public setLeagueSelected(kLeague: KLeague): void {
        this.leagueSelected = kLeague;
    }

    @Mutation
    public setIsGetLeaguesLoading(isLoading: boolean): void {
        this.isGetLeaguesLoading = isLoading;
    }

    @Action
    public async retrieveLeagues(): Promise<void> {
        this.context.commit('setIsGetLeaguesLoading', true);
        const data: KLeague[] = await LolService.getAllLeagues();
        this.context.commit('setLeagues', data);
        this.context.commit('setLeagueSelected', data[0]);
        this.context.commit('setIsGetLeaguesLoading', false);
    }

    @Action
    public updateLeagueSelected(league: KLeague): void {
        this.context.commit('setLeagueSelected', league);
    }

    get getLeagues(): KLeague[] {
        return this.leagues;
    }

    get getIsGetLeaguesLoading(): boolean {
        return this.isGetLeaguesLoading;
    }

    get getLeagueSelected(): KLeague {
        return this.leagueSelected;
    }

}
